import React from "react";

const Fotter = () => {
  return (
    <footer className="bg-dark text-white text-center py-3 mt-auto">
         <p className="text-center">© 2024 Copyright FLAWSKIN. All Rights Reserved.</p> 
        
    </footer>
  );
};

export default Fotter;
